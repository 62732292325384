export const TEXT_SAVE = "TEXT_SAVE"
export const TEXT_DASHBOARD = "TEXT_DASHBOARD"
export const TEXT_PRODUCT = "TEXT_PRODUCT"
export const TEXT_PRODUCTS = "TEXT_PRODUCTS"
export const TEXT_PRODUCTS_INVOICED = "TEXT_PRODUCTS_INVOICED"
export const TEXT_PRODUCTS_REFUNDED = "TEXT_PRODUCTS_REFUNDED"
export const TEXT_CONTRACT = "TEXT_CONTRACT"
export const TEXT_FILTERS = "TEXT_FILTERS"
export const TEXT_SEARCH = "TEXT_SEARCH"
export const TEXT_LANGUAGE = "TEXT_LANGUAGE"
export const TEXT_LOGOUT = "TEXT_LOGOUT"
export const TEXT_LOGIN = "TEXT_LOGIN"
export const TEXT_CANCEL = "TEXT_CANCEL"
export const TEXT_CODE = "TEXT_CODE"
export const TEXT_PERCENTAGE = "TEXT_PERCENTAGE"
export const TEXT_LOAD_TEMPLATE = "TEXT_LOAD_TEMPLATE"
export const TEXT_ADD_NEW = "TEXT_ADD_NEW"
export const TEXT_EDIT = "TEXT_EDIT"
export const TEXT_DELETE = "TEXT_DELETE"

export const TEXT_FILTER_PRODUCT_PLACEHOLDER = "TEXT_FILTER_PRODUCT_PLACEHOLDER"
export const TEXT_FILTER_CONTRACT_PLACEHOLDER = "TEXT_FILTER_CONTRACT_PLACEHOLDER"
export const TEXT_RUN_TEST_BILLING = "TEXT_RUN_TEST_BILLING"
export const TEXT_RUN_BILLING = "TEXT_RUN_BILLING"
export const TEXT_DESCRIPTION = "TEXT_DESCRIPTION"
export const TEXT_VALID_FROM = "TEXT_VALID_FROM"
export const TEXT_VALID_TO = "TEXT_VALID_TO"
export const TEXT_VALIDITY = "TEXT_VALIDITY"
export const TEXT_FROM = "TEXT_FROM"
export const TEXT_TO = "TEXT_TO"
export const TEXT_PROPERTY_NUMBER = "TEXT_PROPERTY_NUMBER"
export const TEXT_GNR_BNR = "TEXT_GNR_BNR"
export const TEXT_SERIALNUMBER = "TEXT_SERIALNUMBER"
export const TEXT_CONTRACTTYPE = "TEXT_CONTRACTTYPE"
export const TEXT_APARTMENT_NUMBER = "TEXT_APARTMENT_NUMBER"
export const TEXT_POST_ADDRESS = "TEXT_POST_ADDRESS"
export const TEXT_POST_CODE = "TEXT_POST_CODE"
export const TEXT_UTILITYUNITS_AND_PRODUCTS = "TEXT_UTILITYUNITS_AND_PRODUCTS"
export const TEXT_ACTIVE_PRODUCTS = "TEXT_ACTIVE_PRODUCTS"
export const TEXT_COUNT_ACTIVE_PRODUCTS = "TEXT_COUNT_ACTIVE_PRODUCTS"
export const TEXT_OWNER_FULL_NAME = "TEXT_OWNER_FULL_NAME"
export const TEXT_OWNER_RECIPIENT = "TEXT_OWNER_RECIPIENT"
export const TEXT_COUNT_RESIDENTIAL_NUMBER = "TEXT_COUNT_RESIDENTIAL_NUMBER"
export const TEXT_UTILITY_UNIT = "TEXT_UTILITY_UNIT"
export const TEXT_COUNT_UTILITY_UNITS = "TEXT_COUNT_UTILITY_UNITS"
export const TEXT_INVOICE_RECEIVER = "TEXT_INVOICE_RECEIVER"
export const TEXT_OWNER = "TEXT_OWNER"
export const TEXT_PRODUCT_DETAILS = "TEXT_PRODUCT_DETAILS"
export const TEXT_ADD_PRODUCT = "TEXT_ADD_PRODUCT"
export const TEXT_CREDIT_NOTE = "TEXT_CREDIT_NOTE"
export const TEXT_CREATE_CREDIT_NOTE = "TEXT_CREATE_CREDIT_NOTE"
export const TEXT_CREATE_CREDIT_NOTE_FOR_LINES = "TEXT_CREATE_CREDIT_NOTE_FOR_LINES"
export const TEXT_INVOICE_LINE_SELECTED = "TEXT_INVOICE_LINE_SELECTED"
export const TEXT_PRICE = "TEXT_PRICE"
export const TEXT_PREVIOUS_VERSIONS = "TEXT_PREVIOUS_VERSIONS"
export const TEXT_VERSION = "TEXT_VERSION"
export const TEXT_UPDATED = "TEXT_UPDATED"
export const TEXT_INVOICES = "TEXT_INVOICES"
export const TEXT_DATE = "TEXT_DATE"
export const TEXT_PRODUCT_NAME = "TEXT_PRODUCT_NAME"
export const TEXT_CUBIT_BILLING = "TEXT_CUBIT_BILLING"
export const TEXT_ADDRESS = "TEXT_ADDRESS"
export const TEXT_TERMIN = "TEXT_TERMIN"
export const TEXT_CASE_NUMBER = "TEXT_CASE_NUMBER"
export const TEXT_SHARED_INVOICE = "TEXT_SHARED_INVOICE"
export const TEXT_SHARED_REFUND_TXT = "TEXT_SHARED_REFUND_TXT"
export const TEXT_DATE_SENT = "TEXT_DATE_SENT"
export const TEXT_MIGRATE_DATA = "TEXT_MIGRATE_DATA"
export const TEXT_MIGRATE_DATA_HEADER = "TEXT_MIGRATE_DATA_HEADER"
export const TEXT_MIGRATION_IN_PROGRESS = "TEXT_MIGRATION_IN_PROGRESS"
export const TEXT_STATUS = "TEXT_STATUS"
export const TEXT_STATUS_ACTIVE = "TEXT_STATUS_ACTIVE"
export const TEXT_STATUS_DRAFT = "TEXT_STATUS_DRAFT"
export const TEXT_STATUS_HISTORIC = "TEXT_STATUS_HISTORIC"

export const TEXT_SETTINGS = "TEXT_SETTINGS"
export const TEXT_USER = "TEXT_USER"
export const TEXT_WELCOME_TO = "TEXT_WELCOME_TO"
export const TEXT_PASSWORD = "TEXT_PASSWORD"

export const TEXT_E_MAIL = "TEXT_E_MAIL"
export const TEXT_NAME = "TEXT_NAME"
export const TEXT_NUMBER = "TEXT_NUMBER"
export const TEXT_TYPE = "TEXT_TYPE"
export const TEXT_UNAUTHORIZED = "TEXT_UNAUTHORIZED"
export const TEXT_FILTER = "TEXT_FILTER"

export const TEXT_CHIMNEY = "TEXT_CHIMNEY"
export const TEXT_HOUSING_UNIT = "TEXT_HOUSING_UNIT"
export const TEXT_AUDIT = "TEXT_AUDIT"

export const TEXT_REQUIRED = "TEXT_REQUIRED"
export const TEXT_ALREADY_EXISTS = "TEXT_ALREADY_EXISTS"
export const TEXT_NOT_FOUND = "TEXT_NOT_FOUND"
export const TEXT_INVALID = "TEXT_INVALID"
export const TEXT_TYPE_NUMBER = "TEXT_TYPE_NUMBER"

export const TEXT_NO_INVOICES = "TEXT_NO_INVOICES"
export const TEXT_TEST_INVOICES = "TEXT_TEST_INVOICES"
export const TEXT_TOTAL_PRICE = "TEXT_TOTAL_PRICE"
export const TEXT_PERIOD = "TEXT_PERIOD"
export const TEXT_LINE = "TEXT_LINE"
export const TEXT_QUANTITY = "TEXT_QUANTITY"
export const TEXT_LINE_TOTAL = "TEXT_LINE_TOTAL"
export const TEXT_UNIT_PRICE = "TEXT_UNIT_PRICE"
export const TEXT_SUM = "TEXT_SUM"
export const TEXT_NETSUM = "TEXT_NETSUM"
export const TEXT_NETAMOUNT = "TEXT_NETAMOUNT"
export const TEXT_VATSUM = "TEXT_VATSUM"
export const TEXT_SUM_ALL_PRODUCTS = "TEXT_SUM_ALL_PRODUCTS"
export const TEXT_LAST_INVOICING = "TEXT_LAST_INVOICING"
export const TEXT_LAST_TEST_INVOICING = "TEXT_LAST_TEST_INVOICING"
export const TEXT_INVOICE_DATE = "TEXT_INVOICE_DATE"
export const TEXT_TEST_DATE = "TEXT_TEST_DATE"
export const TEXT_CONVERT_TO_INVOICE = "TEXT_CONVERT_TO_INVOICE"
export const TEXT_ADD_VAT_CODE = "TEXT_ADD_VAT_CODE"
export const TEXT_VAT_CODE = "TEXT_VAT_CODE"
export const TEXT_FRACTION = "TEXT_FRACTION"
export const TEXT_MVA = "TEXT_MVA"
export const TEXT_PRODUCT_NUMBER = "TEXT_PRODUCT_NUMBER"
export const TEXT_PRD_NO = "TEXT_PRD_NO"
export const TEXT_SYNC_ERP = "TEXT_SYNC_ERP"

export const TEXT_PRODUCTTYPE_CHIMNEY = "Chimney"
export const TEXT_PRODUCTTYPE_HOUSINGUNIT = "HousingUnit"
export const TEXT_PRODUCTTYPE_AUDIT = "Audit"
export const TEXT_PRODUCTSTATUS_ACTIVE = "Active"
export const TEXT_PRODUCTSTATUS_DRAFT = "Draft"
export const TEXT_PRODUCTSTATUS_HISTORIC = "Historic"
export const TEXT_PRODUCT_BILLING_TYPE = "Product billing type"
export const TEXT_PRODUCT_PERIODIC = "Periodic"
export const TEXT_PRODUCT_ACTIVITY_BASED = "Activity based"
export const TEXT_PRODUCT_INVOICE_PERIODS = "TEXT_PRODUCT_INVOICE_PERIODS"
export const TEXT_PRODUCT_EACH_PERIOD = "TEXT_PRODUCT_EACH_PERIOD"
export const TEXT_PRODUCT_EACH_MAIN_PERIOD = "TEXT_PRODUCT_EACH_MAIN_PERIOD"
export const TEXT_PRODUCT_SELECT_PERIOD = "TEXT_PRODUCT_SELECT_PERIOD"

export const TEXT_EDIT_CONTRACT_PRODUCTS = "TEXT_EDIT_CONTRACT_PRODUCTS"
export const TEXT_ADD_CONTRACT_PRODUCT = "TEXT_ADD_CONTRACT_PRODUCT"
export const TEXT_PRODUCT_LINE_SELECTED = "TEXT_PRODUCT_LINE_SELECTED"
export const TEXT_PRODUCTS_TO_UPDATE = "TEXT_PRODUCTS_TO_UPDATE"

export const TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS = "TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS"
export const TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS =
    "TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS"

export const TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_2 =
    "TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_2"

export const TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_3 =
    "TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_3"

export const TEXT_CONTRACT_USE_CUSTOM_TERM = "TEXT_CONTRACT_USE_CUSTOM_TERM"
export const TEXT_CONTRACT_USE_CUSTOM_TERMS_TOOLTIP = "TEXT_CONTRACT_USE_CUSTOM_TERMS_TOOLTIP"
export const TEXT_MAIN_PERIOD = "TEXT_MAIN_PERIOD"

export const TEXT_SELECT_PERIOD_COUNT = "TEXT_SELECT_PERIOD_COUNT"
export const TEXT_PERIODCONFIG_TERMS = "TEXT_PERIODCONFIG_TERMS"

export const TEXT_VALIDATION_ALREADY_EXISTS = "TEXT_VALIDATION_ALREADY_EXISTS"
export const TEXT_VALIDATION_NOT_FOUND = "TEXT_VALIDATION_NOT_FOUND"
export const TEXT_VALIDATION_INVALID_VALUE = "TEXT_VALIDATION_INVALID_VALUE"
export const TEXT_VALIDATION_MUST_BE_NUMBER = "TEXT_VALIDATION_MUST_BE_NUMBER"

export const TEXT_VALIDATION_DATE_FROM_SMALLER = "TEXT_VALIDATION_DATE_FROM_SMALLER"
export const TEXT_VALIDATION_DATE_INVALID = "TEXT_VALIDATION_DATE_INVALID"

export const TEXT_NOT_INVOICED_LINES = "TEXT_NOT_INVOICED_LINES"

export const TEXT_ERP_KOMTEK_FILE = "TEXT_ERP_KOMTEK_FILE"
export const TEXT_VISMA_FILE = "TEXT_VISMA_FILE"
export const TEXT_TERMS = "TEXT_TERMS"
export const TEXT_RUN_CONTRACT_MIGRATION = "TEXT_RUN_CONTRACT_MIGRATION"
export const TEXT_KOMTEK_CHANGE_FILE = "TEXT_KOMTEK_CHANGE_FILE"
export const TEXT_DUETT_FILE = "TEXT_DUETT_FILE"

export const TEXT_PRODUCT_UPDATED_ICON_TOOLTIP = "TEXT_PRODUCT_UPDATED_ICON_TOOLTIP"

export const TEXT_CONTRACT_MAIN_INFO_SERIAL_NUMBER_ERROR = "TEXT_CONTRACT_MAIN_INFO_SERIAL_NUMBER_ERROR"
export const TEXT_NOT_ANY = "TEXT_NOT_ANY"

export const TEXT_GENERAL_CONTRACT_CONFIG = "TEXT_GENERAL_CONTRACT_CONFIG"
export const TEXT_GENRAL_REFUNDS_CONFIG = "TEXT_GENRAL_REFUNDS_CONFIG"
export const TEXT_SINGLE_INVOICELINE_REFUND_ENABLED = "TEXT_SINGLE_INVOICELINE_REFUND_ENABLED"
export const TEXT_SINGLE_INVOICELINE_REFUND_ENABLED_2 = "TEXT_SINGLE_INVOICELINE_REFUND_ENABLED_2"
export const TEXT_INVOICE_PROCESSING_UPON_IMPORT = "TEXT_INVOICE_PROCESSING_UPON_IMPORT"
export const TEXT_INVOICES_AND_CONTRACTS_CONFIG = "TEXT_INVOICES_AND_CONTRACTS_CONFIG"
export const TEXT_ERP_RESEND_ENABLED = "TEXT_ERP_RESEND_ENABLED"

export const TEXT_SERVICE_URL = "EXT_SERVICE_URL"
export const TEXT_USER_NAME = "TEXT_USER_NAME"
export const TEXT_USER_PASSWORD = "TEXT_USER_PASSWORD"
export const TEXT_COMPANY_ID = "TEXT_COMPANY_ID"
export const TEXT_EXTERNAL_SYSTEM = "TEXT_EXTERNAL_SYSTEM"
export const TEXT_INVOICE_SYSTEM_CONFIG = "TEXT_INVOICE_SYSTEM_CONFIG"
export const TEXT_CLIENT_NUMBER = "TEXT_CLIENT_NUMBER"
export const TEXT_DEFAULT_CLIENT_NUMBER = "TEXT_DEFAULT_CLIENT_NUMBER"

export const TEXT_INVOICE_SYSTEM = "TEXT_INVOICE_SYSTEM"
export const TEXT_CLIENT_NUMBER_SECTION_NAME = "TEXT_CLIENT_NUMBER_SECTION_NAME"
export const TEXT_ADD_CLIENT_NUMBER_PERIOD = "TEXT_ADD_CLIENT_NUMBER_PERIOD"
export const TEXT_APPLICATION_ID = "TEXT_APPLICATION_ID"
export const TEXT_CLIENT_ID = "TEXT_CLIENT_ID"
export const TEXT_DOMAIN = "TEXT_DOMAIN"
export const TEXT_PRODUCT_GROUP = "TEXT_PRODUCT_GROUP"

export const TEXT_INVOICE_PROCESSING_UPON_IMPORT_2 = "TEXT_INVOICE_PROCESSING_UPON_IMPORT_2"
export const TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_2 = "TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_2"
export const TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_3 = "TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_3"
export const TEXT_OBS = "TEXT_OBS"

export const TEXT_USE_BUILDING_NUMBER = "TEXT_USE_BUILDING_NUMBER"
export const TEXT_USE_PERIODIC_FEE_TEXT_FORMAT = "TEXT_USE_PERIODIC_FEE_TEXT_FORMAT"

export const TEXT_EMPTY = "TEXT_EMPTY"
export const TEXT_BUILDING_NUMBER = "TEXT_BUILDING_NUMBER"
export const TEXT_SERIAL_NUMBER = "TEXT_SERIAL_NUMBER"
export const TEXT_CUSTOM_VALUE = "TEXT_CUSTOM_VALUE"
export const TEXT_CONFIGURATION = "TEXT_CONFIGURATION"

export const TEXT_SELECT_CONTRACT_FILE = "TEXT_SELECT_CONTRACT_FILE"
export const TEXT_SELECT_INVOICE_FILE = "TEXT_SELECT_INVOICE_FILE"
export const TEXT_IMPORT_DATE = "TEXT_IMPORT_DATE"
export const TEXT_MUNICIPALITY_NUMBER = "TEXT_MUNICIPALITY_NUMBER"
export const TEXT_IMPORT_KOMTEK_FILES = "TEXT_IMPORT_KOMTEK_FILES"
export const TEXT_KOMTEK_IMPORT = "TEXT_KOMTEK_IMPORT"

export const TEXT_GENERATE_DIFF_FILE = "TEXT_GENERATE_DIFF_FILE"
export const TEXT_KOMTEK_IMPORT_TITLE = "TEXT_KOMTEK_IMPORT_TITLE"

export const TEXT_IMPORTING = "TEXT_IMPORTING"
export const TEXT_DONE = "TEXT_DONE"
export const TEXT_ERROR = "TEXT_ERROR"
export const TEXT_INVOICE_TYPE = "TEXT_INVOICE_TYPE"
export const TEXT_ALL_INVOICE_TYPES = "TEXT_ALL_INVOICE_TYPES"
export const TEXT_PERIODIC_INVOICES = "TEXT_PERIODIC_INVOICES"
export const TEXT_ACTIVITY_BASED_INVOICES = "TEXT_ACTIVITY_BASED_INVOICES"
export const TEXT_SEND_TO_ERP = "TEXT_SEND_TO_ERP"
export const TEXT_PERIODIC_COST_TYPE_RUNNING = "TEXT_PERIODIC_COST_TYPE_RUNNING"
export const TEXT_PERIODIC_COST_TYPE_NORMAL = "TEXT_PERIODIC_COST_TYPE_NORMAL"
export const TEXT_GROUPING_TYPE_BY_PRODUCT = "TEXT_GROUPING_TYPE_BY_PRODUCT"
export const TEXT_YES = "TEXT_YES"
export const TEXT_NO = "TEXT_NO"
export const TEXT_USE_WHOLE_BUILDING_NUMBER = "TEXT_USE_WHOLE_BUILDING_NUMBER"
export const TEXT_USE_SHORT_BUILDING_NUMBER = "TEXT_USE_SHORT_BUILDING_NUMBER"
export const TEXT_OVERRIDE_DEFAULT_SERIAL_NUMBER_WITH = "TEXT_OVERRIDE_DEFAULT_SERIAL_NUMBER_WITH"
export const TEXT_AGRESSO_MIDT = "TEXT_AGRESSO_MIDT"
export const TEXT_REFERENCE_CODE = "TEXT_REFERENCE_CODE"
export const TEXT_RESPONSIBLE = "TEXT_RESPONSIBLE"
export const TEXT_SALESMAN = "TEXT_SALESMAN"
export const TEXT_VOUCHER_TYPE = "TEXT_VOUCHER_TYPE"
export const TEXT_GROUP_BY_CONTRACT = "TEXT_GROUP_BY_CONTRACT"
export const TEXT_GROUP_BY_BUILDING_NUMBER = "TEXT_GROUP_BY_BUILDING_NUMBER"
export const TEXT_UNIT_CODE = "TEXT_UNIT_CODE"
export const TEXT_OK = "TEXT_OK"
export const TEXT_INVOICES_FAILED_SENDING_TO_ERP = "TEXT_INVOICES_FAILED_SENDING_TO_ERP"
export const TEXT_INVOICES_SENT_SUCCESSFULLY_TO_ERP = "TEXT_INVOICES_SENT_SUCCESSFULLY_TO_ERP"
export const TEXT_NEW_INVOICES_CREATED_BY_USER = "TEXT_NEW_INVOICES_CREATED_BY_USER"
export const TEXT_PERIOD_TITLE_CREDITING = "TEXT_PERIOD_TITLE_CREDITING"
export const TEXT_PERIOD_TITLE_NEW_INVOICES = "TEXT_PERIOD_TITLE_NEW_INVOICES"
export const TEXT_TRIPLETEX = "TEXT_TRIPLETEX"
export const TEXT_TRIPLETEX_API_URL = "TEXT_TRIPLETEX_API_URL"
export const TEXT_TRIPLETEX_CONSUMER_TOKEN = "TEXT_TRIPLETEX_CONSUMER_TOKEN"
export const TEXT_TRIPLETEX_EMPLOYEE_TOKEN = "TEXT_TRIPLETEX_EMPLOYEE_TOKEN"
export const TEXT_FILTER_NSO_CONTRACT_PLACEHOLDER = "TEXT_FILTER_NSO_CONTRACT_PLACEHOLDER"
export const TEXT_ORGANIZATION_NUMBER = "TEXT_ORGANIZATION_NUMBER"
export const TEXT_SEARCH_CATEGORYOrganizationNumber = "TEXT_SEARCH_CATEGORYOrganizationNumber"
export const TEXT_ORGANIZATIONS_COUNT = "TEXT_ORGANIZATIONS_COUNT"
export const TEXT_ORGANIZATIONS = "TEXT_ORGANIZATIONS"
export const TEXT_ORGANIZATIONS_AND_PRODUCTS = "TEXT_ORGANIZATIONS_AND_PRODUCTS"
export const TEXT_DIFF_FILE_SELECTION_TITLE = "TEXT_DIFF_FILE_SELECTION_TITLE"
export const TEXT_DIFF_FILE_TERM_FROM = "TEXT_DIFF_FILE_TERM_FROM"
export const TEXT_DIFF_FILE_TERM_TO = "TEXT_DIFF_FILE_TERM_TO"
export const TEXT_FROM_TERM_MUST_BE_SMALLER = "TEXT_FROM_TERM_MUST_BE_SMALLER"
export const TEXT_TO_TERM_MUST_BE_GREATER = "TEXT_TO_TERM_MUST_BE_GREATER"
