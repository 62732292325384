import { InvoicePeriodStatistic } from "models/invoice-period-statistic"
import { AnyAction } from "redux"

export const ACTION_LOAD_PERIOD_STATISTICS = "ACTION_LOAD_STATISTICS"
export const ACTION_LOAD_PERIOD_STATISTICS_SUCCESS = "ACTION_LOAD_STATISTICS_SUCCESS"
export const ACTION_UPDATE_PERIOD_STATISTICS = "ACTION_UPDATE_PERIOD_STATISTICS"
export const ACTION_UPDATE_PERIOD_STATISTICS_SUCCESS = "ACTION_UPDATE_PERIOD_STATISTICS_SUCCESS"
export const ACTION_SET_IS_LOADING_IN_PROGRESS = "ACTION_SET_IS_LOADING_IN_PROGRESS"

export const actionLoadPeriodStatisticsSuccess = (statistics: InvoicePeriodStatistic[]): AnyAction => ({
    type: ACTION_LOAD_PERIOD_STATISTICS_SUCCESS,
    statistics,
})

export const actionLoadPeriodStatistics = (year: number): AnyAction => ({
    type: ACTION_LOAD_PERIOD_STATISTICS,
    year,
})

export const actionUpdatePeriodStatistics = (year: number, period: number): AnyAction => ({
    type: ACTION_UPDATE_PERIOD_STATISTICS,
    year,
    period,
})

export const actionUpdatePeriodStatisticsSuccess = (invoiceStatistic: InvoicePeriodStatistic): AnyAction => ({
    type: ACTION_UPDATE_PERIOD_STATISTICS_SUCCESS,
    invoiceStatistic,
})

export const actionSetLoadingInProgress = (isInProgress: boolean): AnyAction => ({
    type: ACTION_SET_IS_LOADING_IN_PROGRESS,
    isInProgress,
})
