import React, { useState } from "react"
import LayoutDefault from "common/layout/layout-default"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_DASHBOARD,
    TEXT_MIGRATE_DATA,
    TEXT_MIGRATE_DATA_HEADER,
    TEXT_RUN_CONTRACT_MIGRATION,
} from "../../utils/translations/keys"
import TitleToolbar from "common/title-toolbar/title-toolbar"
import Navigation from "common/navigation/navigation"
import Spinner from "common/spinner"
import { RouteComponentProps } from "@reach/router"
import { makeStyles, Theme, Button } from "@material-ui/core"
import { InvoiceFileWidget } from "./invoice-file-widget"
import DashboardClient from "clients/dashboard-client"
import { PeriodAccordions } from "./period-accordions"
import { InvoicePeriodStatistic } from "models/invoice-period-statistic"
import { ContractDialog } from "../contract-page/contract-dialog"
import InvoiceUpdateProgressNotification from "common/notifications/invoice-update-progress"
import BillingJobClient from "clients/billing-job-client"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "redux/app/app-store"
import { actionLoadPeriodStatistics, actionUpdatePeriodStatistics } from "../../redux/dashboard/period-statistics-actions"
import { actionLoadInvoiceUpdateProgress } from "../../redux/billing-job/billing-job-actions"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRighIcon from "@material-ui/icons/ChevronRight"
import { actionDashboardYearUpdate } from "../../redux/config/config-action"

const useStyles = makeStyles((theme: Theme) => ({
    flex: {
        display: "flex",
    },
    layout: {
        "max-width": "1400px",
    },
    yearSelectorBoxContainer: {
        display: 'flex',
        fontSize: 20,
        justifyContent: 'center',
        paddingBottom: 30
    },
    yearSelectorArrowLeft: {
        marginRight: 20
    },
    yearSelectorArrowRight: {
        marginLeft: 20
    },

    yearSelectorBox: {
        
    },
}))

const DashboardPage: React.FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const [isMigrationRunning, setIsMigrationRunning] = useState(false)
    const [isMigrationDialogOpen, setMigrationDialogOpen] = useState(false)
    const configuration = useSelector((state: AppState) => state.billingConfiguration.billingConfig)
    const statistics = useSelector((state: AppState) => state.invoicePeriodStatistics.items)
    const tenantConfigs = useSelector((state: AppState) => state.config.tenantConfig)
    const periodConfigs = useSelector((state: AppState) => state.config.periodConfigurations)
    const invoiceUpdateResponse = useSelector((state: AppState) => state.invoiceUpdateState.invoiceUpdateState)
    const year = useSelector((state: AppState) => state.config.dashboardYear)

    const years = periodConfigs ? periodConfigs.map(x => x.year) : []

    const updateStatistic = (statistic: InvoicePeriodStatistic) => {
        dispatch(actionUpdatePeriodStatistics(statistic.year, statistic.period))
    }

    const migrateData = async () => {
        setIsMigrationRunning(true)
        await DashboardClient.migrateData().toPromise()
        setIsMigrationRunning(false)
    }

    const handleMigrationDialogOpen = () => {
        setMigrationDialogOpen(true)
    }

    const handleMigrationDialogClose = () => {
        setMigrationDialogOpen(false)
    }

    const updateInvoices = async () => {
        await BillingJobClient.updateInvoices({}).toPromise()
        dispatch(actionLoadInvoiceUpdateProgress())
    }

    const handleYearChange = (value) => {
        const current = year + value

        if(years.includes(current)){
            dispatch(actionLoadPeriodStatistics(current))
            dispatch(actionDashboardYearUpdate(current))
        }
    }

    const toolbar = (
        <TitleToolbar title={tr(TEXT_DASHBOARD)}>
            {configuration.isMigrationEnabled ? (
                <Button variant="outlined" color="inherit" onClick={() => handleMigrationDialogOpen()}>
                    {tr(TEXT_MIGRATE_DATA_HEADER)}
                </Button>
            ) : null}
        </TitleToolbar>
    )

    return (
        <LayoutDefault navigation={<Navigation />} toolbar={toolbar} showMunicipalities={true}>
            <div className={styles.layout}>
                {tenantConfigs?.isInvoiceUpdateJobEnabled && invoiceUpdateResponse?.hasUpdates && (
                    <InvoiceUpdateProgressNotification
                        invoiceUpdateResponse={invoiceUpdateResponse}
                        showUpdatedEntities={true}
                        updateInvoices={updateInvoices}
                    ></InvoiceUpdateProgressNotification>
                )}
                <div className={styles.yearSelectorBoxContainer}>
                <ChevronLeftIcon className={styles.yearSelectorArrowLeft} onClick={e => handleYearChange(-1)} ></ChevronLeftIcon>
                    <div className={styles.yearSelectorBox}>
                        {year}
                    </div>
                    <ChevronRighIcon className={styles.yearSelectorArrowRight} onClick={e => handleYearChange(1)}></ChevronRighIcon>
                </div>
                <PeriodAccordions
                    statistics={statistics}
                    isResendEnabled={configuration.isResendEnabled}
                    configuration={configuration}
                    updateStatistic={updateStatistic}
                ></PeriodAccordions>
                <div className={styles.flex}>{configuration.enableFilesDashboard && <InvoiceFileWidget />}</div>
            </div>
            <ContractDialog
                title={tr(TEXT_MIGRATE_DATA_HEADER)}
                open={isMigrationDialogOpen}
                handleClose={handleMigrationDialogClose}
                handleOk={() => migrateData()}
                okText={tr(TEXT_MIGRATE_DATA)}
            >
                {tr(TEXT_RUN_CONTRACT_MIGRATION)}
                {isMigrationRunning && <Spinner />}
            </ContractDialog>
        </LayoutDefault>
    )
}

export { DashboardPage }
