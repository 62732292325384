import DashboardClient from "clients/dashboard-client"
import { Observable, EMPTY, of } from "rxjs"
import { AnyAction } from "redux"
import {
    ACTION_LOAD_PERIOD_STATISTICS,
    ACTION_LOAD_PERIOD_STATISTICS_SUCCESS,
    actionLoadPeriodStatisticsSuccess,
    ACTION_UPDATE_PERIOD_STATISTICS,
    actionUpdatePeriodStatisticsSuccess,
} from "./period-statistics-actions"
import { flatMap, map, withLatestFrom, mergeMap, concatMap, filter } from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import { defaultCatchError } from "../app/app-epics"

export const loadPeriodStatistics = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(ACTION_LOAD_PERIOD_STATISTICS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                DashboardClient.getInvoicePeriodStatistics(action.year).pipe(
                    map(result => actionLoadPeriodStatisticsSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const updatePeriodStatistics = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(ACTION_UPDATE_PERIOD_STATISTICS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                DashboardClient.refreshPeriodStatistics(action.year, action.period).pipe(
                    map(result => actionUpdatePeriodStatisticsSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const periodStatisticsEpics = combineEpics(loadPeriodStatistics, updatePeriodStatistics)
