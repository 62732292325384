import {
    Grid,
    makeStyles,
    Theme,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core"
import { store } from "../../index"
import DashboardClient from "clients/dashboard-client"
import { InvoicePeriodStatistic } from "models/invoice-period-statistic"
import React, { useEffect, useState } from "react"
import { tr } from "utils/translations/translate"
import { ContractDialog } from "../contract-page/contract-dialog"
import { BillingConfiguration } from "models/billing-configuration"
import { InvoicesSendResult } from "models/invoices-send-result"
import { actionCubitSnackbarShow } from "../../redux/cubit-snackbar/cubit-snackbar-actions"
import { Period } from "./period"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "redux/app/app-store"
import { actionSetLoadingInProgress } from "../../redux/dashboard/period-statistics-actions"
import { actionLoadInvoiceFiles } from "../../redux/invoice-file/invoice-file-table-actions"
import {
    TEXT_DIFF_FILE_SELECTION_TITLE,
    TEXT_DIFF_FILE_TERM_FROM,
    TEXT_DIFF_FILE_TERM_TO,
    TEXT_FROM_TERM_MUST_BE_SMALLER,
    TEXT_GENERATE_DIFF_FILE,
    TEXT_GROUPING_TYPE_BY_PRODUCT,
    TEXT_GROUP_BY_BUILDING_NUMBER,
    TEXT_TERMIN,
    TEXT_TO_TERM_MUST_BE_GREATER,
    TEXT_YES,
} from "utils/translations/keys"
import {
    GROUPING_TYPE_BY_BUILDING,
    GROUPING_TYPE_BY_PRODUCT,
    GROUPING_TYPE_NONE,
    PERIODIC_TYPE_RUNNING,
} from "./dashboard-constants"
import { useUser } from "../../redux/auth/auth-selectors"
import { InvoicingSystemType } from "models/tenant-config"
import { SendToErpDialog } from "./send-to-erp-dialog"
import ErrorIcon from "@material-ui/icons/Error"
import clsx from "clsx"
const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            margin: "8px",
        },
        header: {
            padding: "0 62px 0 24px",
        },
        detailsHeader: {
            paddingRight: "38px",
        },
        numericColumn: {
            textAlign: "right",
        },
        expansionPanel: {
            borderRadius: "0!important",
            boxShadow: "none",
            background: "transparent",
            margin: "6px 0 0 0",
            "&::before": {
                display: "none",
            },
            "&.Mui-expanded": {
                margin: "6px 0 0 0",
            },
            "& > .MuiExpansionPanelSummary-root": {
                background: "white",
                "&.Mui-expanded": {
                    minHeight: "initial",
                    margin: "initial",
                },
                "& > .MuiExpansionPanelSummary-content.Mui-expanded": {
                    margin: 0,
                },
            },
            "& > .MuiCollapse-container": {
                marginTop: "2px",
                background: "white",
            },
        },
        tableRow: {
            "&:last-child td": {
                borderBottom: 0,
            },
            "&:hover": {
                cursor: "pointer",
            },
        },
        sendToErp: {
            marginTop: "1rem",
        },
        sendToErpContainer: {
            paddingTop: "2rem",
        },
        subtotals: {
            borderTop: "1px #f0f0f0 solid",
            paddingTop: "0.25rem",
            marginTop: "0.25rem",
        },
        periodSelectionLabel: {
            fontSize: 14,
        },
        periodSelect: {
            maxWidth: 160,
        },
        errorIcon: {
            color: "rgba(0, 0, 0, 0.54)",
        },
        errorDescription: {
            paddingLeft: 10,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.54)",
        },
        paddingTop: {
            paddingTop: 13,
        },
        containerMargins: {
            marginBottom: 20,
            marginTop: 20,
        },
        iconContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }
})

const selectedPeriod: InvoicePeriodStatistic = { period: 0, year: 0, products: [] }
const defaultInvoicesSendResult: InvoicesSendResult = { failureCount: 0, successCount: 0, problemCategories: {} }

export const PeriodAccordions: React.FC<{
    statistics: InvoicePeriodStatistic[]
    isResendEnabled?: boolean
    configuration?: BillingConfiguration
    updateStatistic: (statistic: InvoicePeriodStatistic) => void
}> = props => {
    const dispatch = useDispatch()
    const [isSendToErpRunning, setIsSendToErpRunning] = useState(false)
    const [numberOfPaymentInvoicesToSend, setNumberOfPaymentInvoicesToSend] = useState(0)
    const [inputNumberOfPaymentInvoicesToSend, setInputNumberOfPaymentInvoicesToSend] = useState<string>("")
    const [isSendToErpDialogOpen, setSendToErpDialogOpen] = useState(false)
    const [invoicesSendResult, setInvoicesSendResult] = useState<InvoicesSendResult>(defaultInvoicesSendResult)
    const [selectedPeriodForSending, setSelectedPeriodForSending] = useState<InvoicePeriodStatistic>(selectedPeriod)
    const isUpdatingStatistics = useSelector((state: AppState) => state.invoicePeriodStatistics.isUpdatingStatistics)
    const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig)
    const [invoiceType, setInvoiceType] = useState(0)
    const [periodicCostType, setPeriodicCostType] = useState(PERIODIC_TYPE_RUNNING)
    const [groupingType, setGroupingType] = useState(GROUPING_TYPE_BY_PRODUCT)
    const [diffFileGroupingType, setDiffFileGroupingType] = useState(GROUPING_TYPE_NONE)
    const [generateDiffFileDialogOpen, setGenerateDiffFileDialogOpen] = useState(false)
    const [sendToErpFinished, setSendToErpFinished] = useState(false)
    const user = useUser()
    const styles = useStyles()
    const isVisma = tenantConfig?.invoiceSystem.toString() === InvoicingSystemType[InvoicingSystemType.Visma]
    const periodConfig = useSelector((state: AppState) => state.config.periodConfigurations)

    const generateDiffFile = async () => {
        if (!selectedPeriodForSending) return
        setIsSendToErpRunning(true)
        setGenerateDiffFileDialogOpen(false)
        setIsLoading(true)
        const selectedDiffDateFromObj = JSON.parse(selectedDiffDateFrom)
        await DashboardClient.generateDiffFile(
            selectedDiffDateFromObj.year,
            selectedDiffDateFromObj.period,
            selectedPeriodForSending.year,
            selectedPeriodForSending.period,
            diffFileGroupingType
        ).toPromise()
        setIsLoading(false)
        setIsSendToErpRunning(false)
        dispatch(actionLoadInvoiceFiles())
    }

    const handleGenerateDiffFile = period => {
        setGenerateDiffFileDialogOpen(true)
        setSelectedPeriodForSending(period)
    }

    const handleGenereateDiffFileClose = () => {
        setGenerateDiffFileDialogOpen(false)
    }

    const sendToErp = async () => {
        if (selectedPeriodForSending == null) {
            return
        }
        const numberOfInvoices =
            inputNumberOfPaymentInvoicesToSend.length > 0 ? parseInt(inputNumberOfPaymentInvoicesToSend) : undefined
        if (numberOfInvoices !== undefined && numberOfInvoices <= 0) {
            store.dispatch(actionCubitSnackbarShow(["Number of input should be more than 0"], "warning"))
            return
        }
        setIsSendToErpRunning(true)
        const invoicesSendResult = await DashboardClient.sendPeriodToErp(
            selectedPeriodForSending.year,
            selectedPeriodForSending.period,
            invoiceType,
            periodicCostType,
            groupingType,
            numberOfInvoices
        ).toPromise()
        setIsSendToErpRunning(false)

        if (invoicesSendResult) {
            setInvoicesSendResult(invoicesSendResult !== null ? invoicesSendResult : defaultInvoicesSendResult)
            refresh(selectedPeriodForSending)
            dispatch(actionLoadInvoiceFiles())
            setSendToErpFinished(true)

            if (invoicesSendResult.failureCount === 0 && !isVisma) {
                handleSendToErpDialogClose()
            }
        } else {
            setInvoicesSendResult(defaultInvoicesSendResult)
            setSendToErpDialogOpen(false)
        }
    }

    const setIsLoading = (isInProgress: boolean) => {
        dispatch(actionSetLoadingInProgress(isInProgress))
    }

    const refresh = async (statistic: InvoicePeriodStatistic) => {
        props.updateStatistic(statistic)
    }

    const handleSendToErpDialogOpen = (period: InvoicePeriodStatistic) => {
        setSelectedPeriodForSending(period)
        setSendToErpDialogOpen(true)

        setNumberOfPaymentInvoicesToSend(
            !props.isResendEnabled ? period.paymentInvoicesNotTransferred ?? 0 : period.paymentInvoiceCount ?? 0
        )

        setInputNumberOfPaymentInvoicesToSend("")
    }

    const handleSendToErpDialogClose = () => {
        setSelectedPeriodForSending(selectedPeriod)
        setSendToErpDialogOpen(false)
        setNumberOfPaymentInvoicesToSend(0)
        setInvoicesSendResult(defaultInvoicesSendResult)
        setInputNumberOfPaymentInvoicesToSend("")
        setSendToErpFinished(false)
    }

    const onInvoiceTypeChange = value => {
        setInvoiceType(value)
    }

    const handleChangePeriodicType = value => {
        setPeriodicCostType(parseInt(value))
    }

    const isKomtek = tenantConfig
        ? tenantConfig.invoiceSystem.toString() === InvoicingSystemType[InvoicingSystemType.KomTekFile]
        : false

    const handleChangeGroupingType = value => {
        setGroupingType(parseInt(value))
    }

    const onDateFromChange = value => {
        setSelectedDiffDateFrom(value)
        console.log(value)
    }

    const onDateToChange = value => {
        setSelectedDiffDateTo(value)
        console.log(value)
    }

    const getPrevPeriod = () => {
        if (selectedPeriodForSending.period === 1 && periodConfig) {
            const prevYearConfig = periodConfig.find(x => x.year === selectedPeriodForSending.year - 1)

            if (prevYearConfig) {
                return {
                    period: prevYearConfig.periods[prevYearConfig.periods.length - 1].periodNumber,
                    year: prevYearConfig.year,
                }
            }
            return { period: selectedPeriodForSending.period, year: selectedPeriodForSending.year }
        }
        return { period: selectedPeriodForSending.period - 1, year: selectedPeriodForSending.year }
    }

    useEffect(() => {
        setSelectedDiffDateFrom(JSON.stringify(getPrevPeriod()))
        setSelectedDiffDateTo(
            JSON.stringify({ period: selectedPeriodForSending.period, year: selectedPeriodForSending.year })
        )
    }, [selectedPeriodForSending])

    const [selectedDiffDateFrom, setSelectedDiffDateFrom] = useState(JSON.stringify(getPrevPeriod()))
    const [selectedDiffDateTo, setSelectedDiffDateTo] = useState(
        JSON.stringify({ period: selectedPeriodForSending.period, year: selectedPeriodForSending.year })
    )

    const hasValidPeriodSelection = () => {
        const fromPeriod = JSON.parse(selectedDiffDateFrom)
        const toPeriod = JSON.parse(selectedDiffDateTo)

        if (fromPeriod.year === toPeriod.year) {
            return toPeriod.period >= fromPeriod.period
        }
        return fromPeriod.year > toPeriod.year ? false : true
    }

    return (
        <div className={styles.container}>
            <Grid container className={styles.header}>
                <Grid item xs={1}>
                    <strong>{tr("TEXT_TERMIN")}</strong>
                </Grid>
                <Grid item xs={1}>
                    <strong>{tr("TEXT_TERMIN_START")}</strong>
                </Grid>
                <Grid item xs={2}>
                    <strong>{tr("TEXT_TERMIN_END")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_DOCUMENTS_NUMBER")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_NETAMOUNT")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_VATAMOUNT")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_AMOUNT")}</strong>
                </Grid>
            </Grid>
            {props.statistics
                .filter(x => !!x.startDate)
                .map((period, index) => (
                    <Period
                        key={index}
                        period={period}
                        index={index}
                        handleSendToErpDialogOpen={handleSendToErpDialogOpen}
                        setIsLoading={setIsLoading}
                        isResendEnabled={props.isResendEnabled}
                        refresh={refresh}
                        isLoading={isUpdatingStatistics}
                        generateDiffFile={() => handleGenerateDiffFile(period)}
                    ></Period>
                ))}
            <ContractDialog
                title={tr(TEXT_GENERATE_DIFF_FILE)}
                open={generateDiffFileDialogOpen}
                handleClose={handleGenereateDiffFileClose}
                handleOk={() => generateDiffFile()}
                okText={tr(TEXT_GENERATE_DIFF_FILE)}
                showOk={hasValidPeriodSelection()}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{tr(TEXT_GROUPING_TYPE_BY_PRODUCT)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            aria-label="grouping type"
                            name="groupingType"
                            onChange={e => setDiffFileGroupingType(parseInt(e.target.value))}
                            value={diffFileGroupingType}
                        >
                            <FormControlLabel value={GROUPING_TYPE_NONE} control={<Radio />} label={tr(TEXT_YES)} />
                            <FormControlLabel
                                value={GROUPING_TYPE_BY_BUILDING}
                                control={<Radio />}
                                label={tr(TEXT_GROUP_BY_BUILDING_NUMBER)}
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} className={styles.containerMargins}>
                        <Typography>{tr(TEXT_DIFF_FILE_SELECTION_TITLE)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="select-date-from-input-label" className={styles.periodSelectionLabel}>
                            {tr(TEXT_DIFF_FILE_TERM_FROM)}
                        </InputLabel>
                        <Select
                            className={styles.periodSelect}
                            labelId="select-date-from-input-label"
                            onChange={e => onDateFromChange(e.target.value)}
                            value={selectedDiffDateFrom}
                        >
                            {periodConfig?.map(x => {
                                return x.periods.map(y => (
                                    <MenuItem
                                        key={`${x.year}-${y.periodNumber}`}
                                        value={JSON.stringify({
                                            period: y.periodNumber,
                                            year: x.year,
                                        })}
                                    >
                                        {tr(TEXT_TERMIN)} {y.periodNumber} {x.year}
                                    </MenuItem>
                                ))
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="select-date-to-input-label" className={styles.periodSelectionLabel}>
                            {tr(TEXT_DIFF_FILE_TERM_TO)}
                        </InputLabel>
                        <Select
                            className={styles.periodSelect}
                            labelId="select-date-to-input-label"
                            onChange={e => onDateToChange(e.target.value)}
                            value={selectedDiffDateTo}
                        >
                            {periodConfig?.map(x => {
                                return x.periods.map(y => (
                                    <MenuItem
                                        key={`${x.year}-${y.periodNumber}`}
                                        value={JSON.stringify({
                                            period: y.periodNumber,
                                            year: x.year,
                                        })}
                                    >
                                        {tr(TEXT_TERMIN)} {y.periodNumber} {x.year}
                                    </MenuItem>
                                ))
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={1} className={clsx(styles.containerMargins, styles.iconContainer)}>
                        <ErrorIcon className={styles.errorIcon}></ErrorIcon>
                    </Grid>
                    <Grid item xs={11} className={styles.containerMargins}>
                        <Typography className={clsx(styles.errorDescription, styles.paddingTop)}>
                            {tr(TEXT_FROM_TERM_MUST_BE_SMALLER)}
                        </Typography>
                        <Typography className={styles.errorDescription}>
                            {tr(TEXT_TO_TERM_MUST_BE_GREATER)}
                        </Typography>
                    </Grid>
                </Grid>
            </ContractDialog>
            <SendToErpDialog
                groupingType={groupingType}
                handleChangeGroupingType={handleChangeGroupingType}
                handleChangePeriodicType={handleChangePeriodicType}
                handleSendToErpDialogClose={handleSendToErpDialogClose}
                inputNumberOfPaymentInvoicesToSend={inputNumberOfPaymentInvoicesToSend}
                invoiceType={invoiceType}
                invoicesSendResult={invoicesSendResult}
                isKomtek={isKomtek}
                isSendToErpDialogOpen={isSendToErpDialogOpen}
                isSendToErpRunning={isSendToErpRunning}
                numberOfPaymentInvoicesToSend={numberOfPaymentInvoicesToSend}
                onInvoiceTypeChange={onInvoiceTypeChange}
                periodicCostType={periodicCostType}
                sendToErp={sendToErp}
                setInputNumberOfPaymentInvoicesToSend={setInputNumberOfPaymentInvoicesToSend}
                styles={styles}
                user={user}
                sendToErpFinished={sendToErpFinished}
                showInvoiceCount={isVisma}
            ></SendToErpDialog>
        </div>
    )
}
