import { makeReducer } from "utils"
import { ActionReducer, Reducers } from "shared-components/src/utils/make-reducer"
import {
    ACTION_CONTRACT_GET,
    ACTION_CONTRACT_GET_SUCCESS,
    ACTION_LOAD_CONTRACTS,
    ACTION_LOAD_CONTRACTS_SUCCESS,
    ACTION_GET_TEST_INFO,
    ACTION_GET_TEST_INFO_SUCCESS,
    ACTION_UPDATE_CONTRACT_FILTER,
    ACTION_SEARCH_CONTRACTS,
    ACTION_SEARCH_CONTRACTS_SUCCESS,
    ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE,
    ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE_SUCCESS,
    ACTION_UPDATE_CONTRACT_CUSTOM_TERMS_SUCCESS,
    ACTION_UPDATE_CONTRACT_SERIAL_NUMBER_SUCCESS,
    ACTION_CLEAR_CONTRACTS,
    ACTION_LOAD_CONTRACT_INVOICES_SUCCESS,
    ACTION_UPDATE_CONTRACT_INVOICES_IS_SELECTED,
} from "./contract-actions"
import { Contract, ContractListItem } from "models/contract"
import { findIndex } from "lodash"
import { ContractSearchItem } from "models/contract-search-item"
import { InvoiceLine } from "models/invoice"
import { InvoiceType } from "common/enums/invoiceType"
import { InvoiceParty } from "models/create-invoice"

export type ContractState = {
    items: ContractListItem[]
    autoCompleteContracts: ContractSearchItem[]
    filter: string
    isLoading: boolean
    isLoadingAutocomplete: boolean
    selectedContract: Contract
    selectedContractInvoiceLines: InvoiceLine[]
    selectedContractUnsentInvoiceLines: InvoiceLine[]
}

export type UpdateInvoiceIsSelectedType = {
    id: string
    isSelected: boolean
    lineNo: number
}

type ContractReducer = ActionReducer<ContractState>

const getContract: ContractReducer = state => state

const getContractSuccess: ContractReducer = (state, action) => {
    const items = [...state.items]
    const index = findIndex(items, g => g.id === action.contract.id)
    index < 0 ? items.push(action.contract) : (items[index] = action.contract)
    return {
        ...state,
        items: items,
        selectedContract: action.contract,
    }
}

const loadContracts: ContractReducer = state => state

const loadContractsSuccess: ContractReducer = (state, action) => {
    return { ...state, items: action.contracts, isLoading: false }
}

const searchContracts: ContractReducer = (state, action) => {
    return {
        ...state,
        filter: action.searchFilter,
        isLoading: true,
    }
}

const searchContractsSuccess: ContractReducer = (state, action) => {
    return {
        ...state,
        items: action.contracts,
        isLoading: false,
    }
}

const searchContractsAutocomplete: ContractReducer = state => ({
    ...state,
    isLoadingAutocomplete: true,
    autoCompleteContracts: [
        {
            category: "Unknown",
            value: "",
            childValues: [],
        },
    ],
})

const searchContractsAutocompleteSuccess: ContractReducer = (state, action) => ({
    ...state,
    autoCompleteContracts: action.results,
    isLoadingAutocomplete: false,
})

const getLastTestInfo: ContractReducer = state => state

const getLastTestInfoSuccess: ContractReducer = (state, action) => ({
    ...state,
    lastTestInfo: action.info,
})

const UpdateFilter: ContractReducer = (state, action) => ({
    ...state,
    filter: action.filter,
})

const updateCustomTermsSuccess: ContractReducer = (state, action) => {
    return {
        ...state,
        selectedContract: {
            ...state.selectedContract,
            useCustomTerms: action.contract.useCustomTerms,
            periodType: action.contract.periodType,
            selectedPeriods: action.contract.selectedPeriods,
        },
    }
}

const updateContractSerialNumberSuccess: ContractReducer = (state, action) => {
    return {
        ...state,
        selectedContract: {
            ...state.selectedContract,
            serialNumber: action.serialNumber,
        },
    }
}

const clearContracts: ContractReducer = (state, action) => {
    return {
        ...state,
        items: [],
        autoCompleteContracts: [],
    }
}

const loadContractInvoiceSuccess: ContractReducer = (state, action) => {
    const invoiceLines = action.invoices.flatMap(x =>
        x.details.map(d => ({ ...d, ...x, invoiceId: x.id } as InvoiceLine))
    )
    const unsentInvoiceLines = invoiceLines.filter(x => !x.transferDate)

    return {
        ...state,
        selectedContractInvoiceLines: invoiceLines,
        selectedContractUnsentInvoiceLines: unsentInvoiceLines,
    }
}

const updateContractInvoicesIsSelected: ContractReducer = (state, action) => {
    const invoiceLines = state.selectedContractInvoiceLines.map(x => {
        const updatedInvoice = action.singleLineSelection
            ? action.invoices.find(invoice => invoice.id === x.id && invoice.lineNo === x.lineNo)
            : action.invoices.find(invoice => invoice.id === x.id)

        if (updatedInvoice && x.type !== InvoiceType.Refund && !x.markedForRefund) {
            return {
                ...x,
                isSelected: updatedInvoice.isSelected,
            }
        } else {
            return x
        }
    })

    const unsentInvoiceLines = invoiceLines.filter(x => !x.transferDate)

    return {
        ...state,
        selectedContractInvoiceLines: invoiceLines,
        selectedContractUnsentInvoiceLines: unsentInvoiceLines,
    }
}

const reducers: Reducers<ContractState> = {
    [ACTION_CONTRACT_GET]: getContract,
    [ACTION_CONTRACT_GET_SUCCESS]: getContractSuccess,
    [ACTION_LOAD_CONTRACTS]: loadContracts,
    [ACTION_LOAD_CONTRACTS_SUCCESS]: loadContractsSuccess,
    [ACTION_SEARCH_CONTRACTS]: searchContracts,
    [ACTION_SEARCH_CONTRACTS_SUCCESS]: searchContractsSuccess,
    [ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE]: searchContractsAutocomplete,
    [ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE_SUCCESS]: searchContractsAutocompleteSuccess,
    [ACTION_GET_TEST_INFO]: getLastTestInfo,
    [ACTION_GET_TEST_INFO_SUCCESS]: getLastTestInfoSuccess,
    [ACTION_UPDATE_CONTRACT_FILTER]: UpdateFilter,
    [ACTION_UPDATE_CONTRACT_CUSTOM_TERMS_SUCCESS]: updateCustomTermsSuccess,
    [ACTION_UPDATE_CONTRACT_SERIAL_NUMBER_SUCCESS]: updateContractSerialNumberSuccess,
    [ACTION_CLEAR_CONTRACTS]: clearContracts,
    [ACTION_LOAD_CONTRACT_INVOICES_SUCCESS]: loadContractInvoiceSuccess,
    [ACTION_UPDATE_CONTRACT_INVOICES_IS_SELECTED]: updateContractInvoicesIsSelected,
}

const initialState = {
    items: [],
    autoCompleteContracts: [],
    isLoading: false,
    filter: "",
    isLoadingAutocomplete: false,
    selectedContract: {
        id: "",
        cityId: 0,
        unitId: "",
        gnr: "",
        bnr: "",
        fnr: "",
        snr: "",
        type: "",
        lastSyncedDate: undefined,
        isProductBasedContract: false,
        serialNumber: "",
        ownerFullName: "",
        invoiceRecipientFullName: "",
        residentialInfo: [],
        address: undefined,
        ownerAddress: undefined,
        invoiceRecipientAddress: undefined,
        metaData: {},
        useCustomTerms: false,
        periodType: "",
        selectedPeriods: [],
        parties: [],
    },
    selectedContractInvoiceLines: [],
    selectedContractUnsentInvoiceLines: [],
}

export const contractReducer = makeReducer<ContractState>(reducers, initialState)
