import { combineEpics, ofType } from "redux-observable"
import { Observable } from "rxjs"
import { filter, flatMap, map, withLatestFrom } from "rxjs/operators"
import { AnyAction } from "redux"
import {
    ACTION_CONTRACT_GET,
    actionContractGetSuccess,
    ACTION_LOAD_CONTRACTS,
    actionLoadContractsSuccess,
    actionSearchContractsSuccess,
    ACTION_SEARCH_CONTRACTS,
    ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE,
    actionSearchContractsAutocompleteSuccess,
    ACTION_UPDATE_CONTRACT_CUSTOM_TERMS,
    actionUpdateContractCustomTermsSuccess,
    actionLoadContractInvoicesSuccess,
    ACTION_LOAD_CONTRACT_INVOICES,
    ACTION_UPDATE_CONTRACT_CUSTOM_TERMS_SUCCESS,
} from "./contract-actions"
import { defaultCatchError } from "../app/app-epics"
import ContractClient from "clients/contract-client"
import InvoiceClient from "clients/invoice-client"

export const getContract = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        ofType(ACTION_CONTRACT_GET),
        flatMap(
            (action): Observable<AnyAction> =>
                ContractClient.getContract(action.contractId).pipe(
                    map(contract => actionContractGetSuccess(contract)),
                    defaultCatchError()
                )
        )
    )

export const loadContracts = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_LOAD_CONTRACTS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                ContractClient.getContracts().pipe(
                    map(result => actionLoadContractsSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const searchContracts = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_SEARCH_CONTRACTS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                ContractClient.searchContracts(action.searchFilter).pipe(
                    map(result => actionSearchContractsSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const searchContractsAutocomplete = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                ContractClient.searchContractsAutocomplete(action.searchFilter).pipe(
                    map(result => actionSearchContractsAutocompleteSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const updateContractCustomTerms = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(ACTION_UPDATE_CONTRACT_CUSTOM_TERMS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                ContractClient.updateUseCustomTerms(
                    action.contractId,
                    action.useCustomTerms,
                    action.selectedPeriods,
                    action.periodType
                ).pipe(
                    map(result => actionUpdateContractCustomTermsSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const loadContractInvoicesAfterContractUpdate = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(ACTION_UPDATE_CONTRACT_CUSTOM_TERMS_SUCCESS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                ContractClient.getInvoices(action.contract.id).pipe(
                    map(result => actionLoadContractInvoicesSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const loadContractInvoices = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(ACTION_LOAD_CONTRACT_INVOICES),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                ContractClient.getInvoices(action.contractId).pipe(
                    map(result => actionLoadContractInvoicesSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const contractEpics = combineEpics(
    getContract,
    loadContracts,
    searchContracts,
    searchContractsAutocomplete,
    updateContractCustomTerms,
    loadContractInvoices,
    loadContractInvoicesAfterContractUpdate
)
