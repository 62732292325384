import MunicipalityClient from "clients/municipality-client"
import { Observable, EMPTY, of } from "rxjs"
import { AnyAction } from "redux"
import {
    actionChangeMunicipality,
    actionMunicipalityLoadSuccess,
    MUNICIPALITY_CHANGE,
    MUNICIPALITY_LOAD,
    MUNICIPALITY_LOAD_SUCCESS,
} from "./municipality-actions"
import { flatMap, map, withLatestFrom, mergeMap, concatMap, filter } from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import { defaultCatchError } from "../app/app-epics"
import { getMunicipality, removeMunicipality, setMunicipality } from "utils/municipality/municipalityUtil"
import { actionLoadProducts } from "../../redux/product/product-actions"
import { actionLoadVatCodes } from "../../redux/vatCode/vatCode-actions"
import { actionLoadRefunds } from "../../redux/refund/refund-actions"
import { actionLoadPeriodStatistics } from "../dashboard/period-statistics-actions"
import { actionBillingConfigGet } from "../../redux/billing-config/billing-config-action"
import { actionPeriodConfigurationsGet, actionTenantConfigGet } from "../../redux/config/config-action"
import { actionLoadInvoiceStatistics } from "../../redux/dashboard/invoice-statistics-actions"
import { actionLoadInvoiceUpdateProgress } from "../../redux/billing-job/billing-job-actions"

export const loadMunicipalities = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(MUNICIPALITY_LOAD),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                MunicipalityClient.getMunicipalities().pipe(
                    map(result => actionMunicipalityLoadSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const municipalityLoaded = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(MUNICIPALITY_LOAD_SUCCESS),
        withLatestFrom(state$),
        mergeMap(([action, state]): Observable<AnyAction> => {
            if (state.municipalities.items && state.municipalities.items.length) {
                let municipalityNumber = getMunicipality()
                let municipality = state.municipalities.items.find(x => x.value === municipalityNumber)

                if (municipalityNumber === undefined || municipality === undefined) {
                    municipality = state.municipalities.items[0]
                }
                return of(actionChangeMunicipality(municipality))
            } else {
                removeMunicipality()
            }
            return of(
                actionLoadProducts(),
                actionLoadRefunds(),
                actionLoadVatCodes(),
                actionLoadPeriodStatistics(state.config.dashboardYear),
                actionBillingConfigGet(),
                actionTenantConfigGet(),
                actionPeriodConfigurationsGet(),
                actionLoadInvoiceStatistics(),
                actionLoadInvoiceUpdateProgress()
            )
        })
    )
}

export const changeMunicipality = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(MUNICIPALITY_CHANGE),
        withLatestFrom(state$),
        mergeMap(([action, state]): Observable<AnyAction> => {
            setMunicipality(action.municipality.value)

            return of(
                actionLoadProducts(),
                actionLoadRefunds(),
                actionLoadVatCodes(),
                actionLoadPeriodStatistics(state.config.dashboardYear),
                actionBillingConfigGet(),
                actionTenantConfigGet(),
                actionPeriodConfigurationsGet(),
                actionLoadInvoiceStatistics(),
                actionLoadInvoiceUpdateProgress()
            )
            //EMPTY
        })
    )
}

export const municipalityEpics = combineEpics(loadMunicipalities, municipalityLoaded, changeMunicipality)
