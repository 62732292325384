import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Theme, Select, MenuItem, FormControl, InputLabel, NativeSelect, StepIconClasskey, TextField } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { tr } from 'utils/translations/translate'
import { Contract } from 'models/contract';
import Spinner from 'common/spinner';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/app/app-store';
import RefundClient from 'clients/refund-client';
import { Product, ProductStatus } from 'models/product';
import InvoiceClient from 'clients/invoice-client';
import { toShortDate } from 'utils/time/timeUtil';
import { InvoiceCreatePeriod } from 'models/invoice-create-period';
import { CreateInvoiceLine, InvoiceParty } from 'models/create-invoice';
import classNames from 'classnames';
import { InvoiceType } from 'common/enums/invoiceType';
import { getDecimalString, getInvoicePartyAddressFormatted } from 'utils/invoice/invoice-utils';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  tableHead: {
    fontWeight: 600,
    borderBottom: "none",
    paddingLeft: 0
  },
  tableColumn: {
    borderBottom: "none",
    paddingLeft: 0
  },
  dialogTitle: {
    paddingBottom: "0"
  },
  titleCase: {
    textTransform: "capitalize"
  },
  invoiceReceiverSelect: {
    display: "inline-block",
    width: "300px",
    verticalAlign: "middle"
  },
  infoField: {
    lineHeight: "32px",
    verticalAlign: "middle",
    display: "inline-block",
    width: "128px"
  },
  addNewProduct: {
    width: "400px",
    borderRadius: 0
  },
  alignRight: {
    textAlign: "right"
  }
}))

type CreateInvoiceDialogProps = {
  contract: Contract,
  open: boolean,
  handleOk: () => void,
  handleClose: () => void
}
export const CreateInvoiceDialog: React.FC<CreateInvoiceDialogProps> = props => {
  const { open, handleClose, handleOk, contract } = props

  const styles = useStyles()
  
  const refundedLines = useSelector((state: AppState) => state.refunds.lastRefundedLines)
  const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig)
  const [periodInfo, setPeriodInfo] = useState<InvoiceCreatePeriod>()
  const [lines, setLines] = useState<CreateInvoiceLine[]>([])
  const [updateCount, setUpdateCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [createInvoiceDisabled, setCreateInvoiceDisabled] = useState(false)
  const products = useSelector((state: AppState) => state.products.items) || {}
  const residentialInfoProducts = contract.residentialInfo.flatMap(x => x.products)
  const [invoiceReceivers, setInvoiceReceivers] = useState<(InvoiceParty | undefined)[]>([])
  const [selectedInvoiceReceiver, setSelectedInvoiceReceiver] = useState<InvoiceParty | undefined>(undefined)
  const [nsoDescription, setNsoDescription] = useState('')

  const activeProducts = tenantConfig?.isContractBasedInvoicing &&
  contract.residentialInfo && 
  contract.residentialInfo.length && 
  residentialInfoProducts.length ?
  products.filter(x => (x.status || {}).value === ProductStatus.Active && residentialInfoProducts.findIndex(cp => x.productId === cp.productId) !== -1):
  products.filter(x => (x.status || {}).value === ProductStatus.Active)
  
  useEffect(() => {
    if (open) {
      createLines();
      setCreateInvoiceDisabled(false)
    }
  }, [open])

  useEffect(() => {
    if(tenantConfig?.isNsoTenant && refundedLines && refundedLines[0].organizationNumber){
      getInvoiceLineDescription(refundedLines[0].organizationNumber, refundedLines[0].productId, lines[0].periodYear)
    }
  }, [lines])

  useEffect(() => {
    if(tenantConfig?.isNsoTenant){
      const updatedLines = lines.map((x) => ({
        ...x,
        description: nsoDescription
      }))
      setLines(updatedLines)
    }
  }, [nsoDescription])
  

  useEffect(() => {
    if (periodInfo && refundedLines) {
      
      const lines = refundedLines.map((x, index) => 
        {
          return {
            invoiceDate: periodInfo.invoiceDate,
            period: tenantConfig ? x.period : periodInfo.period,
            periodYear: tenantConfig?.isContractBasedInvoicing ? x.periodYear : periodInfo.periodYear,
            numerator: x.numerator,
            denominator: x.type === InvoiceType.Invoice ? x.denominator * periodInfo.periodCount : x.denominator,
            productId: x.productId,
            description: x.description,
            lineNo: index + 1,
            invoiceId: x.invoiceId,
            invoiceParty: x.invoiceParty,
            organizationNumber: tenantConfig?.isNsoTenant ? x.details[0].organizationNumber : '' 
        }as CreateInvoiceLine
      })

      setLines(lines);

      const contractParties = contract.parties ?? []
           
      const invoiceParties = lines.map(x => x.invoiceParty)
      .filter(x => x?.address && (x?.firstName || x?.lastName || x?.companyName) && 
      contractParties.findIndex(cp => cp.uniqueId === x?.uniqueId) === -1)
      
      const invoiceReceivers = contractParties || invoiceParties ? 
      [...contractParties , ...invoiceParties] : []
      const defaultParty = contract.parties?.find(() => true)

      setInvoiceReceivers(invoiceReceivers)
      setSelectedInvoiceReceiver(defaultParty)
    }
  }, [periodInfo, refundedLines])

  const getInvoiceLineDescription = async (organizationNumber: string, productId: string, periodYear: number) => {
    const description = (await InvoiceClient.getNewInvoiceDescription(contract.id, organizationNumber, productId, periodYear).toPromise())
    setNsoDescription(description)
  }

  const forceUpdate = () => {
    return setUpdateCount(updateCount => updateCount + 1)
  }

  const createLines = async () => {
    if (refundedLines?.length) {
      setIsLoading(true);
      setPeriodInfo(await InvoiceClient.getInvoiceCreationPeriod().toPromise());
      setIsLoading(false);
    }
  }

  const addNewProduct = () => {
    if (!periodInfo) {
      return;
    }

    const lineNo = Math.max(...lines.map((p) => p.lineNo), 1) + 1;
     
    setLines([...lines, {
      invoiceDate: periodInfo.invoiceDate,
      period: periodInfo.period,
      periodYear: periodInfo.periodYear,
      numerator: 1,
      denominator: 1,
      description: '',
      lineNo: lineNo,
      invoiceParty: undefined
    }]);
  }

  const calculateLinePrice = (line: CreateInvoiceLine, product: Product | undefined) => {
    if (!product || !periodInfo) {
      return null;
    }
    const lineTotal = product.price * (line.numerator / line.denominator)
    return product.vatPercentage > 0 ? lineTotal + product.vatPercentage / 100: lineTotal
  }

  const chooseProduct = (line: CreateInvoiceLine, productId: string) => {
    line.productId = productId;
    forceUpdate();
  }

  const changeLineDenominator = (line: CreateInvoiceLine, denominator: number) => {
    line.denominator = denominator;
    forceUpdate();
  }

  const removeLine = (line: CreateInvoiceLine) => {
    const _lines = [...lines];
    _lines.splice(_lines.findIndex(x => x === line), 1);
    setLines(_lines);
  }

  const createInvoicesOk = async () => {

    setCreateInvoiceDisabled(true)

    if (!refundedLines || !refundedLines.length || lines.findIndex(x => !x.productId) !== -1) {
      return;
    }

    setIsLoading(true);

    var linesToRefund = refundedLines.map(x => ({
      InvoiceId: x.invoiceId,
      LineNumber: x.lineNo,
      Quantity: x.quantity
    }));
    
    await RefundClient.refund({
      linesToRefund
    });

    const linesWithNewInvoiceReceiver = lines.map(x => {
      return {...x,
              invoiceParty: selectedInvoiceReceiver}
    })
  
    await InvoiceClient.createInvoiceLines({
      contractId: contract.id,
      invoiceLines: linesWithNewInvoiceReceiver
    }).toPromise();

    setIsLoading(false)

    handleOk();
  }

  const onChangeDescription = (line: CreateInvoiceLine, description: string) => {
    const _lines = [...lines];
    const updatedLine = { ...line, description: description};
    _lines.splice(_lines.findIndex(x => x === line), 1, updatedLine);
    setLines(_lines);
  }

  const onChangeReceiver = (e: any) =>{
    setSelectedInvoiceReceiver(invoiceReceivers.find(x => x?.uniqueId === e.target.value))
  }

  return (
      <Dialog open={open} maxWidth="xl">
          <DialogTitle className={styles.dialogTitle}>{tr("TEXT_CREATE_NEW_INVOICE")}</DialogTitle>
          <DialogContent style={{ width: "80vw", height: "60vh" }}>
              {isLoading && <Spinner />}
              {!isLoading && (
                  <>
                      <div>
                          <strong className={styles.infoField}>{tr("TEXT_INVOICE_RECEIVER")}: </strong>

                          <FormControl variant="standard" style={{ maxWidth: "300px", verticalAlign: "middle" }}>
                              <Select value={selectedInvoiceReceiver?.uniqueId ?? 0} onChange={e => onChangeReceiver(e)}>
                                  {invoiceReceivers.map((x, index) => (
                                      <MenuItem value={x?.uniqueId} key={x?.uniqueId}>
                                          <span>{ x?.companyName && x?.companyName.length > 0 ? tenantConfig?.isNsoTenant && x.email ? `${x?.companyName} (${x.email})` : x?.companyName : `${x?.firstName} ${x?.lastName}`}</span>
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </div>
                      <div>
                          <strong className={styles.infoField}>{tr("TEXT_INVOICE_ADDRESS")}: </strong>
                          <span>{getInvoicePartyAddressFormatted(selectedInvoiceReceiver)}</span>
                      </div>
                      <Table className={styles.table} size="small">
                          <TableHead>
                              <TableRow>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_TERMIN")}</TableCell>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_INVOICE_DATE")}</TableCell>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_PRODUCT")}</TableCell>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_DESCRIPTION")}</TableCell>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_FRACTION")}</TableCell>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_PRICE")}</TableCell>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_MVA")}</TableCell>
                                  <TableCell className={styles.tableHead}>{tr("TEXT_SUM")}</TableCell>
                                  <TableCell className={styles.tableHead}></TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {lines.map(line => {
                                  const product = activeProducts.find(x => x.id === line.productId)
                                  return (
                                      <TableRow key={line.lineNo}>
                                          <TableCell className={styles.tableColumn}>
                                              {line.period} {line.periodYear}
                                          </TableCell>
                                          <TableCell className={styles.tableColumn}>
                                              {toShortDate(line.invoiceDate) || "-"}
                                          </TableCell>
                                          <TableCell className={styles.tableColumn}>
                                              <FormControl variant="standard" style={{ minWidth: "300px" }}>
                                                  <Select
                                                      value={line.productId || ""}
                                                      onChange={item =>
                                                          chooseProduct(line, item.target.value as string)
                                                      }
                                                  >
                                                      {activeProducts.map(product => (
                                                          <MenuItem value={product.id} key={product.id}>
                                                              <span>{product.name}</span>
                                                          </MenuItem>
                                                      ))}
                                                  </Select>
                                              </FormControl>
                                          </TableCell>
                                          <TableCell className={styles.tableColumn}>
                                              <TextField
                                                  value={line.description}
                                                  onChange={text => onChangeDescription(line, text.target.value)}
                                              ></TextField>
                                          </TableCell>
                                          <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                              <span>{line.numerator} / </span>
                                              <FormControl
                                                  variant="standard"
                                                  style={{ maxWidth: "40px", verticalAlign: "middle" }}
                                              >
                                                  <Select
                                                      value={line.denominator}
                                                      onChange={item =>
                                                          changeLineDenominator(line, item.target.value as number)
                                                      }
                                                  >
                                                      {[...Array(20)].map((_, index) => (
                                                          <MenuItem value={index + 1} key={index}>
                                                              <span>{index + 1}</span>
                                                          </MenuItem>
                                                      ))}
                                                  </Select>
                                              </FormControl>
                                          </TableCell>
                                          <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                              {getDecimalString(product?.price ?? 0)}
                                          </TableCell>
                                          <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                              {product?.vatPercentage ? product?.vatPercentage + " %" : "-"}
                                          </TableCell>
                                          <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                              {getDecimalString(calculateLinePrice(line, product) ?? 0)}
                                          </TableCell>
                                          <TableCell className={styles.tableColumn}>
                                              <DeleteOutlineIcon
                                                  style={{ color: "#aaa", cursor: "pointer" }}
                                                  onClick={() => removeLine(line)}
                                              ></DeleteOutlineIcon>
                                          </TableCell>
                                      </TableRow>
                                  )
                              })}
                          </TableBody>
                      </Table>
                  </>
              )}
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button variant="outlined" className={styles.addNewProduct} onClick={addNewProduct}>
                      {tr("TEXT_ADD_PRODUCT")}
                  </Button>
              </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} color="primary">
                  {tr("TEXT_CANCEL")}
              </Button>
              <Button
                  onClick={createInvoicesOk}
                  disabled={createInvoiceDisabled}
                  variant="contained"
                  color="primary"
                  autoFocus
              >
                  {tr("TEXT_CREATE_NEW_INVOICE")}
              </Button>
          </DialogActions>
      </Dialog>
  )
}
