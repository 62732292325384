import { CreateInvoicesRequest } from "models/create-invoice"
import { InvoiceCreatePeriod } from "models/invoice-create-period"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpPost } from "services/httpService"

export default class InvoiceClient {
    public static getInvoiceCreationPeriod(): Observable<InvoiceCreatePeriod> {
        return httpGet<InvoiceCreatePeriod>(apiUrl("/Invoices/create-invoice-period"))
    }
    public static createInvoiceLines(request: CreateInvoicesRequest): Observable<void> {
        return httpPost<void>(apiUrl(`/Invoices/create-invoice-lines`), request)
    }

    public static getNewInvoiceDescription(
        contractId: string,
        organizationNumber: string,
        productId: string,
        periodYear: number
    ): Observable<string> {
        return httpGet<string>(
            apiUrl(
                `/Invoices/new-invoice-description?contractId=${contractId}&organizationNumber=${organizationNumber}&productId=${productId}&periodYear=${periodYear}`
            )
        )
    }
}
